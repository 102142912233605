import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Login.vue"),
    meta: {
      title: "Login",
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Dashboard/Index.vue"),
    meta: {
      title: "Dashboard",
      requiredAuth: true,
    },
  },
  {
    path: "/companies",
    name: "Companies",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Companies/Index.vue"),
    meta: {
      title: "Companies",
      requiredAuth: true,
    },
  },
  {
    path: "/leads/users",
    name: "Users",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Leads/Users.vue"),
    meta: {
      title: "Users",
      requiredAuth: true,
    },
  },
  {
    path: "/leads/suppliers",
    name: "Suppliers",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Leads/Suppliers.vue"),
    meta: {
      title: "Suppliers",
      requiredAuth: true,
    },
  },
  {
    path: "/leads/payment-mode",
    name: "PaymentMode",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Leads/PaymentMode.vue"),
    meta: {
      title: "Payment Mode",
      requiredAuth: true,
    },
  },
  {
    path: "/leads/currencies",
    name: "Currencies",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Leads/Currencies.vue"),
    meta: {
      title: "Currencies",
      requiredAuth: true,
    },
  },
  {
    path: "/leads/clients",
    name: "Clients",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Leads/Clients.vue"),
    meta: {
      title: "Clients",
      requiredAuth: true,
    },
  },
  {
    path: "/inventory/categories",
    name: "Categories",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/Inventory/Categories.vue"
      ),
    meta: {
      title: "Categories",
      requiredAuth: true,
    },
  },
  {
    path: "/inventory/products",
    name: "Products",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Inventory/Products.vue"),
    meta: {
      title: "Products",
      requiredAuth: true,
    },
  },
  {
    path: "/inventory/receive/:action/:reference?",
    name: "ItemsReceive",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/Inventory/ItemsReceive.vue"
      ),
    meta: {
      title: "Receive items from supplier",
      requiredAuth: true,
    },
  },
  {
    path: "/inventory/transfers/:action/:reference?",
    name: "ItemsTransfer",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/Inventory/ItemsTransfer.vue"
      ),
    meta: {
      title: "Transfer items to departments",
      requiredAuth: true,
    },
  },
  {
    path: "/inventory/requisitions/:action/:reference?",
    name: "RequisitionCreator",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/Inventory/RequisitionCreator.vue"
      ),
    meta: {
      title: "Create Requisitions",
      requiredAuth: true,
    },
  },
  {
    path: "/inventory/proforma/:action/:reference?",
    name: "ProformaCreator",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/Inventory/ProformaCreator.vue"
      ),
    meta: {
      title: "Create Proforma",
      requiredAuth: true,
    },
  },
  {
    path: "/inventory/proforma-view/:action/:reference?",
    name: "ProformaView",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/Inventory/ProformaView.vue"
      ),
    meta: {
      title: "Proforma View",
      requiredAuth: true,
    },
  },
  {
    path: "/inventory/delivery-note/:action/:reference?",
    name: "DeliveryNote",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/Inventory/DeliveryNote.vue"
      ),
    meta: {
      title: "Delivery Note",
      requiredAuth: true,
    },
  },
  {
    path: "/inventory/purchase-order-view/:action/:reference?",
    name: "PurchaseOrderView",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/Inventory/PurchaseOrderView.vue"
      ),
    meta: {
      title: "Purchase Order Priview",
      requiredAuth: true,
    },
  },
  {
    path: "/inventory/purchase-order/:action/:reference?",
    name: "PurchaseOrderCreator",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/Inventory/PurchaseOrderCreator.vue"
      ),
    meta: {
      title: "Create Purchase Order",
      requiredAuth: true,
    },
  },
  {
    path: "/inventory/adjustments/:action/:reference?",
    name: "Adjustments",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/Inventory/Adjustments.vue"
      ),
    meta: {
      title: "Adjustments",
      requiredAuth: true,
    },
  },
  {
    path: "/expenses-categories",
    name: "ExpensesCategories",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/Expenses/ExpensesCategories.vue"
      ),
    meta: {
      title: "Expenses Categories",
      requiredAuth: true,
    },
  },
  {
    path: "/expenses",
    name: "Expenses",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Expenses/Expenses.vue"),
    meta: {
      title: "Expenses",
      requiredAuth: true,
    },
  },
  {
    path: "/setings/ItemsUnits",
    name: "ItemsUnits",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Settings/Units.vue"),
    meta: {
      title: "Items Units",
      requiredAuth: true,
    },
  },
  {
    path: "/setings/insurances",
    name: "Insurances",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/Settings/Insurances.vue"
      ),
    meta: {
      title: "Insurances",
      requiredAuth: true,
    },
  },
  {
    path: "/setings/departments",
    name: "Departments",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/Settings/Departments.vue"
      ),
    meta: {
      title: "Departments",
      requiredAuth: true,
    },
  },
  {
    path: "/setings/user-roles",
    name: "UserRoles",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Settings/UserRoles.vue"),
    meta: {
      title: "User and roles",
      requiredAuth: true,
    },
  },
  {
    path: "/reports/top-selling",
    name: "TopSelling",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/Reports/TopSellingReport.vue"
      ),
    meta: {
      title: "Top Selling Report",
      requiredAuth: true,
    },
  },
  {
    path: "/reports/receive/:reference?",
    name: "ReceiveReport",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/Reports/ReceiveReport.vue"
      ),
    meta: {
      title: "Receives Report",
      requiredAuth: true,
    },
  },
  {
    path: "/reports/transfer",
    name: "TransferReport",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/Reports/TransferReport.vue"
      ),
    meta: {
      title: "Transfers Report",
      requiredAuth: true,
    },
  },
  {
    path: "/reports/requisitions/:reference?",
    name: "RequisitionsReport",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/Reports/RequisitionsReport.vue"
      ),
    meta: {
      title: "Requisitions Report",
      requiredAuth: true,
    },
  },
  {
    path: "/reports/proforma/:reference?",
    name: "ProformaReport",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/Reports/ProformaReport.vue"
      ),
    meta: {
      title: "Proforma Report",
      requiredAuth: true,
    },
  },
  {
    path: "/reports/delivery-note/:reference?",
    name: "DeliveryNoteReport",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/Reports/DeliveryNoteReport.vue"
      ),
    meta: {
      title: "Delivery Note Report",
      requiredAuth: true,
    },
  },
  {
    path: "/reports/purchase-order/:reference?",
    name: "PurchaseOrderReport",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/Reports/PurchaseOrderReport.vue"
      ),
    meta: {
      title: "Purchase Order Report",
      requiredAuth: true,
    },
  },
  {
    path: "/reports/stock-status",
    name: "TrackerReport",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/Reports/TrackerReport.vue"
      ),
    meta: {
      title: "Stock Status Report",
      requiredAuth: true,
    },
  },
  {
    path: "/reports/adjustments",
    name: "AdjustmentsReport",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/Reports/AdjustmentsReport.vue"
      ),
    meta: {
      title: "Adjustments Report",
      requiredAuth: true,
    },
  },
  {
    path: "/reports/expired-products",
    name: "ExpiredProducts",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/Reports/ExpiredProducts.vue"
      ),
    meta: {
      title: "Expired Products",
      requiredAuth: true,
    },
  },
  {
    path: "/reports/sales",
    name: "SalesReport",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/Reports/SalesReport.vue"
      ),
    meta: {
      title: "Sales Report",
      requiredAuth: true,
    },
  },
  ,
  {
    path: "/reports/payment-history",
    name: "PaymentHistoryReport",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/Reports/PaymentHistoryReport.vue"
      ),
    meta: {
      title: "Payment Received Report",
      requiredAuth: true,
    },
  },
  {
    path: "/profile",
    name: "UserProfile",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/UserProfile.vue"),
    meta: {
      title: "User Profile",
      requiredAuth: true,
    },
  },
  {
    path: "/new-subscription",
    name: "newSubscription",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/NewSubscription.vue"),
    meta: {
      title: "New Subscription",
    },
  },
  {
    path: "/pos/sales/:reference?",
    name: "PosSale",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/POS/Sale.vue"),
    meta: {
      title: "POS sales",
      requiredAuth: true,
    },
  },
  {
    path: "/pos/receipt/:reference?",
    name: "ReceiptViewLarge",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/POS/ReceiptViewLarge.vue"
      ),
    meta: {
      title: "POS sales",
      requiredAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const token = localStorage.getItem("token");
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiredAuth)) {
    if (token) {
      next();
      return;
    }
    location.replace("/");
  } else {
    next();
  }
});

router.afterEach((to) => {
  document.title = to.meta.title;
});

export default router;
